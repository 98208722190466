<template>
  <div class="mt-30">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="图片" prop="img">
        <uploadOneImg :img-list="ruleForm.img" @uploadimgByValue="uploadimgByValue"></uploadOneImg>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <el-input v-model="ruleForm.content" v-show="false"></el-input>
        <div class="editor-container" style="line-height:22px;width:80%">
          <UE :defaultMsg=defaultMsg :config=config ref="ue" v-if="ue"></UE>
        </div>
      </el-form-item>
      <el-form-item>
        <upload v-on:childByValue="childByValueMore" :fileName="fileZcName" :imgList='files'></upload>
        <!-- <el-carousel height="300px" style="width:600px;margin-top:30px">
          <el-carousel-item v-for="item in ruleForm.lbt" :key="item">
            <img :src="item" alt="" width="600" height="300" >
          </el-carousel-item>
        </el-carousel> -->
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="submitInfo('ruleForm')">保存</el-button>
    </div>
  </div>
</template>
<script>
import UE from '../../components/editor'
import { IntroInfo, IntroSave } from '../../api/api'
import uploadOneImg from '../../components/uploadOneImg'
import { UploadNewsImg } from '../../api/api'
import upload from '../../components/upload'
export default {
  components: {
    uploadOneImg,
    UE,
    upload,
  },
  data() {
    var checkContent = (rule, value, callback, text) => {
      // 获取富文本内容
      let contentUE = this.$refs.ue.getUEContent()
      if (contentUE != '' && contentUE != undefined) {
        this.ruleForm.content = contentUE
        callback()
      } else {
        callback(new Error('请填写资讯内容'))
      }
    }
    return {
      ruleForm: {
        img: '', //图片
        content: '', //内容
        lbt: [], //轮播图
      },
      id: '',

      uploadUrl: '',
      files: [], //默认显示图片
      filesList: [],
      fileZcName: 'zc',

      ue: false,
      defaultMsg: '', //富文本默认值
      config: {
        initialFrameWidth: null,
        initialFrameHeight: 350,
      },
      rules: {
        img: [{ required: true, message: '请选择图片', trigger: 'change' }],
        content: [
          { required: true, trigger: 'change', validator: checkContent },
        ],
      },
    }
  },
  created() {
    this.uploadUrl = UploadNewsImg
    this.getIntroInfo()
  },
  methods: {
    //获取平台简介信息
    getIntroInfo() {
      this.$axios.post(IntroInfo, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.ue = true
            this.ruleForm = res.data.data
            this.defaultMsg = res.data.data.content
            this.ruleForm.content = res.data.data.content
            this.id = res.data.data.id
            for (let i = 0; i < res.data.data.lbt.length; i++) {
              // this.files.push({url: res.data.data.lbt[i]})
              res.data.data.lbt[i] = { url: res.data.data.lbt[i] }
            }

            this.files = res.data.data.lbt
            console.log(res.data.data)
            // this.files = res.data.data.lbt.map(item=>{
            //   return {
            //     url: item
            //   }
            // })
            // console.log(this.files);
            // for (let i = 0; i < this.files.length; i++) {
            //   this.files[i] = {url:this.files[i]}
            // }
          }
        },
        (err) => {
          this.$messgae.error(err.msg)
        }
      )
    },
    //接受图片子组件传回来的值
    uploadimgByValue(val, type) {
      this.ruleForm.img = val
      console.log(this.ruleForm.img)
    },
    //多张图片的子组件返回值
    childByValueMore(childValue, name) {
      this.files = childValue
      console.log(this.files)
    },
    submitInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var zc = []
          if (this.files.length > 0) {
            for (let i = 0; i < this.files.length; i++) {
              if (this.files[i].url != undefined && this.files[i].url != '') {
                zc.push(this.files[i].url)
              } else if (this.files[i] != '' && this.files[i] != undefined) {
                zc.push(this.files[i])
              }
            }
          }
          var db = {
            id: this.id,
            img: this.ruleForm.img,//头像
            content: this.ruleForm.content,//内容
            lbt: zc.length > 0 ? zc.join(",") : "",//轮播图
          }
          this.$axios.post(IntroSave, db).then(
            (res) => {
              if (res.data.code == 1) {
                this.$message({
                  message: '操作成功',
                  type: 'warning',
                })
                this.getIntroInfo()
              }
            },
            (err) => {
              this.$message.error(res.data.msg)
            }
          )
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style scoped>
</style>